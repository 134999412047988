import React from 'react';
import { INavigationBarItem } from './../structures/INavigationBarItem';
import { Link } from 'react-router-dom';

export const NavigationBarItem = (
  props: INavigationBarItem,
): JSX.Element => {
  const { label, urlAddress } = props;

  return (
    <li
      className="p-4
      flex-1 inline-flex justify-center items-center"
    >
      <Link to={urlAddress}>{label}</Link>
    </li>
  );
};
