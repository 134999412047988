import React from 'react';
import { ITextContent } from '../structures/ITextContent';

export const TextContent = (props: ITextContent) => {
  const { label, tailwindCss } = props;

  return (
    <div className={tailwindCss + ' text-gray-600'}>
      <div>{label}</div>
    </div>
  );
};
