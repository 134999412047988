import React from 'react';
import { NavigationBarItem } from './NavigationBarItem';
import { INavigationBar } from '../structures/INavigationBar';
import { JSX } from 'react/jsx-runtime';
import { Outlet } from 'react-router-dom';

export const NavigationBar = (
  props: INavigationBar,
): JSX.Element => {
  const { children } = props;

  const items: JSX.Element[] = [];
  children?.forEach((child) => {
    items.push(
      <NavigationBarItem
        label={child.label}
        urlAddress={child.urlAddress}
      />,
    );
  });

  return (
    <div
      className="top-0 h-12 w-full
      fixed flex justify-center items-center
      bg-gray-200"
    >
      <ul className="">{items}</ul>
      <Outlet />
    </div>
  );
};
