import React from 'react';
import { WebpageContent } from '../elements/WebpageContent';
import { TypeAnimation } from 'react-type-animation';
import { TextContent } from '../elements/TextContent';

export const Landing = () => {
  return (
    <WebpageContent disableNavigationBar={true}>
      {
        <TextContent
          label={
            <TypeAnimation
              sequence={['Harsg', 250, 'Harshad Barapatre']}
              wrapper="div"
              speed={{
                type: 'keyStrokeDelayInMs',
                value: 200,
              }}
              style={{
                display: 'inline-block',
              }}
              repeat={0}
            />
          }
          tailwindCss={
            'text-3xl lg:text-6xl \
           font-black'
          }
        />
      }
    </WebpageContent>
  );
};
