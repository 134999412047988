import React from 'react';
import { NavigationBar } from './NavigationBar';
import { IWebpageContent } from './../structures/IWebpageContent';

export const WebpageContent = (
  props: IWebpageContent,
): JSX.Element => {
  const { children } = props;

  return (
    <div
      className="w-screen h-screen 
    bg-gray-100 
    select-none 
    grid grid-cols-8 gap-4"
    >
      {!props.disableNavigationBar ? (
        <NavigationBar>
          {getMockNavigationBarItems()}
        </NavigationBar>
      ) : null}
      <div
        className="col-start-3 col-span-4
        flex flex-col
        my-auto justify-items-center"
      >
        {children}
      </div>
    </div>
  );
};

function getMockNavigationBarItems() {
  return [
    {
      label: 'Home',
      urlAddress: '/',
    },
    {
      label: 'Test Page',
      urlAddress: '/testpage',
    },
    {
      label: 'Error Page',
      urlAddress: '/123',
    },
  ];
}
