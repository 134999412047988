import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Landing } from './pages/Landing';
import { Error404 } from './pages/Error404';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('webpage') as HTMLElement,
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route index element={<Landing />} />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  </BrowserRouter>,
);
