import React from 'react';
import { WebpageContent } from '../elements/WebpageContent';
import { TextContent } from '../elements/TextContent';

export const Error404 = () => {
  return (
    <WebpageContent disableNavigationBar={true}>
      {
        <div>
          <TextContent
            label={'Error 404: Page Not Found'}
            tailwindCss={'text-3xl lg:text-4xl font-black'}
          />
          <TextContent
            label={
              <p>
                Looks like you&apos;ve wandered off to an
                invalid address, go{' '}
                <a href={'/'} className="underline">
                  home
                </a>
                .
              </p>
            }
            tailwindCss={'font-medium mt-4'}
          />
        </div>
      }
    </WebpageContent>
  );
};
